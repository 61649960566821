function dateFormatHuman(d, w = null) {
  // format date for humans
  const date_in = new Date(d);
  const dayMonth = new Intl.DateTimeFormat("ru-RU", { day: "numeric", month: "short" })
    .format(date_in)
    .replace(".", "");
  const weekShort = new Intl.DateTimeFormat("ru-RU", { weekday: "short" }).format(date_in);
  let res = dayMonth;
  if (w) {
    res = dayMonth + ", " + weekShort;
  }
  return res;
}

function dateFormatJS(d = null) {
  const date_in = new Date(d);
  // format date_in to ISO 8601 Extended, DATE ONLY
  const YYYY = date_in.getFullYear();
  const MM = (date_in.getMonth() + 1 < 10 ? "0" : "") + (date_in.getMonth() + 1);
  const DD = (date_in.getDate() < 10 ? "0" : "") + date_in.getDate();
  return YYYY + "-" + MM + "-" + DD;
}

function pluralYears(n) {
  const declension = ["год", "года", "лет"];
  const cases = [2, 0, 1, 1, 1, 2];
  return declension[n % 100 > 4 && n % 100 < 20 ? 2 : cases[n % 10 < 5 ? n % 10 : 5]];
}

export { dateFormatHuman, dateFormatJS, pluralYears };
