<template>
  <div class="loader">
    <div>
      <span>Загрузка{{ cnt }}</span>
    </div>
  </div>
</template>

<style scoped>
.loader {
  height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: center;
}
</style>

<script>
export default {
  name: "Loader",
  data: function () {
    return {
      cnt: "...",
      interval: null,
    };
  },

  mounted: function () {
    // console.log("setInterval");
    this.interval = setInterval(() => (this.cnt += "."), 500);
  },

  beforeDestroy: function () {
    // console.log("clearInterval");
    clearInterval(this.interval);
  },
};
</script>
