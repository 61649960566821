<template>
  <div>
    <div class="heap">
      <h3 class="center">Зарплата по точкам за период</h3>
      <div>
        <form v-on:submit.prevent="" class="flex">
          <div class="heap-block">
            <DateSelector label="Дата нач." :val="date_begin" @change="date_begin = $event" />
          </div>
          <div class="heap-block">
            <DateSelector label="Дата кон." :val="date_end" @change="date_end = $event" />
          </div>
          <div class="heap-block">
            <PointSelector
              label="Точка"
              allPointsFlag="true"
              :id_pointIn="id_point"
              @change="id_point = $event"
            />
          </div>
          <div class="heap-block">
            <div @click.prevent="tableDataRetrieve" class="high pointer">
              <button class="btn" ref="btn">Обновить</button>
            </div>
          </div>
        </form>
      </div>

      <div class="reminder smalltext">работает сортировка по столбцам</div>

      <table class="t_table">
        <tr style="user-select: none; -webkit-user-select: none">
          <td>№</td>
          <td ref="fullname" @click="sort('fullname')" style="width: 40%" class="pointer">
            ФИО
          </td>
          <td ref="job" @click="sort('job')" style="width: 25%" class="pointer">Должность</td>
          <td ref="hours" @click="sort('hours')" class="pointer">Часы</td>
          <td ref="rate" @click="sort('rate')" class="pointer">Ставка</td>
          <td ref="summary" @click="sort('summary')" class="pointer">Итого</td>
        </tr>
      </table>
    </div>

    <div v-if="loading" class="body">
      <Loader />
    </div>
    <div v-else class="body">
      <template v-if="this.total > 0">
        <table class="t_table">
          <template v-for="(row, i) in report">
            <tr :key="i">
              <td>{{ i + 1 }}</td>
              <td style="width: 40%; text-align: left">
                {{ row.fullname }}
              </td>
              <td style="width: 25%">{{ row.job }}</td>
              <td>{{ row.hours }}</td>
              <td>{{ row.rate }}</td>
              <td>{{ row.summary }}</td>
            </tr>
          </template>
          <tr>
            <td colspan="5"></td>
            <td>
              <b>{{ this.total }}</b>
            </td>
          </tr>
        </table>
      </template>

      <template v-if="this.total < 0">
        <table class="t_table">
          <tr>
            <td colspan="5">нет данных по выбранным параметрам</td>
          </tr>
        </table>
      </template>
    </div>
    <br />
    <br />
  </div>
</template>

<script>
import DateSelector from "@/components/DateSelector.vue";
import PointSelector from "@/components/PointSelector.vue";
import Loader from "@/components/Loader.vue";
import { dateFormatJS } from "@/components-js/dateFormat";
import { DATE_MAX_INTERVAL } from "@/config/settings";
import { request } from "@/components-js/requestSrv";
import { lS } from "@/components-js/localStorage";
const loStorage = new lS();

export default {
  components: { DateSelector, PointSelector, Loader },

  data() {
    return {
      loading: false,
      // @vue-cli removal
      //      spacer: "\u00A0",
      // @vue-cli removal
      date_begin: null,
      date_end: null,
      DATE_MAX_INTERVAL,
      id_point: 0,
      report: [],
      total: 0,
      x: 1,
    };
  },
  beforeCreate() {},

  async mounted() {
    // set default values for dates
    // salary specific - it counts for halfmonths - first half and second half
    // so we need intervals 1 - 15 and 16 - last month day
    const d = new Date();
    let nowDay = d.getDate();
    let nowMonth = d.getMonth();
    let nowYear = d.getFullYear();
    if (nowDay > 16) {
      // need to create 1 - 15 interval
      this.date_begin = new Date(d.setDate(1));
      this.date_end = new Date(d.setDate(15));
    } else {
      this.date_begin = new Date(nowYear, nowMonth - 1, 16);
      this.date_end = new Date(nowYear, nowMonth - 1, new Date(nowYear, nowMonth, 0).getDate());
    }
    this.date_begin = dateFormatJS(this.date_begin);
    this.date_end = dateFormatJS(this.date_end);

    let tmp = loStorage.getObjectProp(this.$route.path, "date_begin");
    if (tmp) this.date_begin = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "date_end");
    if (tmp) this.date_end = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "id_point");
    if (tmp) this.id_point = tmp;
    else this.id_point = "%";

    this.tableDataRetrieve();
  },

  methods: {
    // async request(url, method, data) {
    //   // imported function
    //   return request(url, method, data);
    // },

    async tableDataRetrieve() {
      this.loading = true;

      // check if they are dates?
      if (!new Date(this.date_begin) || !new Date(this.date_end)) return;

      // check dates for begin lower, then end
      if (new Date(this.date_begin) > new Date(this.date_end)) this.date_end = this.date_begin;

      // check for interval is not more, than date_max_interval days
      if (
        new Date(
          new Date(this.date_begin).setDate(
            new Date(this.date_begin).getDate() + this.DATE_MAX_INTERVAL
          )
        ) < new Date(this.date_end)
      )
        this.date_end = dateFormatJS(
          new Date(
            new Date(this.date_begin).setDate(
              new Date(this.date_begin).getDate() + this.DATE_MAX_INTERVAL
            )
          )
        );

      // DB request
      const data = await request(
        "/api/report/salary_per_point",
        "POST",
        {
          id_point: this.id_point,
          date_begin: this.date_begin,
          date_end: this.date_end,
        },
        this.$route.path
      );
      this.loading = false;

      this.total = 0;
      for (let i in data) this.total += parseInt(data[i].summary);

      if (!this.total) this.total = -1;

      this.report = data;
    },

    sort(col) {
      if (!this.report.length) return;
      this.x *= -1;
      if (col) {
        this.report.sort((a, b) => {
          if (isNaN(parseInt(a[col])) || isNaN(parseInt(b[col]))) {
            // text sort
            if (a[col] < b[col]) return -1 * this.x;
            if (a[col] > b[col]) return 1 * this.x;
            return 0;
          } else {
            // digital sort
            if (parseInt(a[col]) < parseInt(b[col])) return -1 * this.x;
            if (parseInt(a[col]) > parseInt(b[col])) return 1 * this.x;
            return 0;
          }
        });

        for (let name in this.$refs) {
          this.$refs[name].style.fontWeight = "normal";
        }
        this.$refs[col].style.fontWeight = "bold";
      }
    },
  },
};
</script>
