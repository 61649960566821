const POINTS_SHORTLY = { 1: "Л", 2: "М", 3: "Б", 4: "Я", 5: "Ц", 6: "Т" };
const DAYTIME = { morning: "утро", evening: "вечер" };

const DEBUG = false;
const HOUR_START = 10;
const HOUR_END = 23;

const HOURS = [];
for (let i = HOUR_START; i <= HOUR_END; i++) HOURS[i] = i;

const HOURSADD = [0, 1, 2, 3, 4, 5, 6];

const DATE_MAX_INTERVAL = 60;
const DATE_DEFAULT_INTERVAL = 6;

const GENDER_VALUE_FULL = ["f", "m", "n", "g", "l", "t"];
const GENDER_VALUE_HOMOPHOBIAN = ["f", "m", "n"];
const GENDER_CAPTION = { f: "жен", m: "муж", n: "не выбран", g: "гей", l: "лесб", t: "транс" };

const STATUS_VALUE = ["work", "fired", "pause"];
const STATUS_CAPTION = { work: "в игре", fired: "уволен", pause: "на паузе" };

const TURN_CLOSE_VAL = "closed";
const TURN_CLOSE_VAL_CAPTION = "ЗАКРЕП";
const TURN_FREE_VAL = "free";
const TURN_FREE_VAL_CAPTION = "СВОБОДНАЯ СМЕНА";
const TURN_REQUEST_VAL = "request";

const TURN_TYPE_ADVICE = "advice";
const TURN_TYPE_ADVICE_CAPTION = "консультация";
const TURN_TYPE_SESSION = "session";
const TURN_TYPE_SESSION_CAPTION = "сеанс";

const WEEKDAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const WEEKDAYS_SHORTLY = {
  Monday: "Пн",
  Tuesday: "Вт",
  Wednesday: "Ср",
  Thursday: "Чт",
  Friday: "Пт",
  Saturday: "Сб",
  Sunday: "Вс",
};

const TIMEZONE_DEFAULT_HOUR = 4,
  TIMEZONE_DEFAULT_MIN = 0;

export {
  POINTS_SHORTLY,
  DEBUG,
  HOURS,
  HOURSADD,
  HOUR_START,
  HOUR_END,
  DATE_MAX_INTERVAL,
  DATE_DEFAULT_INTERVAL,
  GENDER_VALUE_FULL,
  GENDER_VALUE_HOMOPHOBIAN,
  GENDER_CAPTION,
  STATUS_VALUE,
  STATUS_CAPTION,
  WEEKDAYS,
  WEEKDAYS_SHORTLY,
  TIMEZONE_DEFAULT_HOUR,
  TIMEZONE_DEFAULT_MIN,
  DAYTIME,
  TURN_CLOSE_VAL,
  TURN_CLOSE_VAL_CAPTION,
  TURN_FREE_VAL,
  TURN_FREE_VAL_CAPTION,
  TURN_REQUEST_VAL,
  TURN_TYPE_ADVICE,
  TURN_TYPE_ADVICE_CAPTION,
  TURN_TYPE_SESSION,
  TURN_TYPE_SESSION_CAPTION,
};
