<template>
  <div class="high" @click="$refs.point.focus()">
    {{ label }}
    <br />
    <select @change="$emit('change', $event.target.value)" v-model="pointCurrent" ref="point">
      <option v-if="allPointsFlag" value="%" :key="-1">Все точки</option>
      <option v-for="(el, idx) in pointList" :value="el.id" :key="idx">
        {{ el.nickname }}
      </option>
    </select>
  </div>
</template>

<script>
import { request } from "@/components-js/requestSrv";

export default {
  name: "PointSelector",
  props: { label: String, id_pointIn: [Number, String], allPointsFlag: Boolean },

  data: function () {
    return { pointList: null, pointCurrent: null };
  },

  async beforeMount() {
    this.pointList = await request("/api/point/list", "GET");
    // console.log("this.id_pointIn: ", this.id_pointIn);
    if (!this.id_pointIn && this.pointList.length) {
      this.pointCurrent = this.pointList[0].id;
      // console.log("this.pointCurrent: ", this.pointCurrent);
      this.$emit("change", this.pointCurrent);
    }
  },

  watch: {
    id_pointIn: function (newVal) {
      this.pointCurrent = newVal;
    },
  },

  updated() {
    // when element fully loaded from server
    this.$refs.point.value = this.id_pointIn;
  },
};
</script>
